import { ICognitoAuthUser } from './types';
const storageType = localStorage || window.localStorage;

export function SetSessionStorage(key: string, value: string) {
  sessionStorage.setItem(key, value);
}

export function GetSessionStorage(key: string) {
  return sessionStorage.getItem(key);
}

export function SetStorageComponent(key: string, value: string) {
  storageType.setItem(key, value);
}

export function GetStorageComponent(key: string) {
  return storageType.getItem(key);
}

export function DeleteStorageComponent(key: string) {
  return storageType.removeItem(key);
}

export function SetJsonStorageComponent(key: string, value: string) {
  storageType.setItem(key, JSON.stringify(value));
}

export function clearStorageComponent() {
  storageType.clear();
}

export function getUserStorageComponent(key: string) {
  const userInfo = storageType.getItem(key);
  if (userInfo !== null) {
    const item = JSON.parse(userInfo);
    return item;
  }
  return null;
}

export function setCognitoSession(cognitoSession: ICognitoAuthUser) {
  const { signInUserSession } = cognitoSession;
  const { idToken, accessToken } = signInUserSession;
  const { payload } = idToken;
  localStorage.setItem('access_token', accessToken.jwtToken);
  localStorage.setItem('id_token', idToken.jwtToken);
  localStorage.setItem('expires_at', `${payload.exp}`);
}

export function removeCognitoSession() {
  localStorage.removeItem('access_token');
  localStorage.removeItem('id_token');
  localStorage.removeItem('expires_at');
  localStorage.removeItem('userinfo');
}

export function setUtmParams(utmParams: {
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_content: string;
}) {
  const { utm_source, utm_medium, utm_campaign, utm_content } = utmParams;
  storageType.setItem('utm_source', utm_source || '');
  storageType.setItem('utm_medium', utm_medium || '');
  storageType.setItem('utm_campaign', utm_campaign || '');
  storageType.setItem('utm_content', utm_content || '');
}

export function getUtmParams() {
  const utmSource = storageType.getItem('utm_source');
  const utmMedium = storageType.getItem('utm_medium');
  const utmCampaign = storageType.getItem('utm_campaign');
  const utmContent = storageType.getItem('utm_content');
  return { utmSource, utmMedium, utmCampaign, utmContent };
}

export function removeUtmParams() {
  storageType.removeItem('utm_source');
  storageType.removeItem('utm_medium');
  storageType.removeItem('utm_campaign');
  storageType.removeItem('utm_content');
}

export function removeCookies() {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
}

export function clearAllStorage() {
  localStorage.clear();
  sessionStorage.clear();
  removeCookies();
}

interface QueryString<QValue> {
  [key: string]: QValue;
}

export const getQueryStringParams = (query: string): QueryString<string> => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params: any, param) => {
        const [key, value] = param.split('=');
        try {
          const tempValue = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
          // for array query params
          if (tempValue.split(',').length > 1) {
            params[key] = tempValue.split(',');
          } else {
            params[key] = tempValue;
          }
          return params;
        } catch (error) {
          // URIError: URI malformed for query param like ?keyword=%&
          params[key] = value;
          return params;
        }
      }, {})
    : {};
};
