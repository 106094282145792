const {
  REACT_APP_BASE_URL,
  REACT_APP_COGNITO_PROJECT_REGION,
  REACT_APP_COGNITO_REGION,
  REACT_APP_COGNITO_POOL_ID,
  REACT_APP_COGNITO_CLIENT_ID,
  REACT_APP_COGNITO_DOMAIN,
  REACT_APP_COGNITO_IDENTITY_POOL_ID,
  REACT_APP_CALENDLY_URL,
  REACT_APP_CALENDLY_RESCHEDULE_URL,
  REACT_APP_CALENDLY_CANCEL_URL,
  REACT_APP_COMMON_BASE_URL,
  REACT_APP_CALENDLY_INITIAL_INTERVIEW_URL,
  REACT_APP_CALENDLY_TECHNICAL_INTERVIEW_URL,
  REACT_APP_WEBSITE_DOMAIN,
  REACT_APP_GTM_ID,
  REACT_APP_ZENDESK_KEY,
} = process.env;

const config = {
  baseUrl: REACT_APP_BASE_URL,
  cognitoProjectRegion: REACT_APP_COGNITO_PROJECT_REGION,
  cognitoRegion: REACT_APP_COGNITO_REGION,
  cognitoPoolId: REACT_APP_COGNITO_POOL_ID,
  cognitoClientId: REACT_APP_COGNITO_CLIENT_ID,
  cognitoDomain: REACT_APP_COGNITO_DOMAIN,
  cognitoIdentityPoolId: REACT_APP_COGNITO_IDENTITY_POOL_ID,
  calendlyUrl: REACT_APP_CALENDLY_URL,
  initialInterviewCalendlyUrl: REACT_APP_CALENDLY_INITIAL_INTERVIEW_URL,
  technicalInterviewCalendlyUrl: REACT_APP_CALENDLY_TECHNICAL_INTERVIEW_URL,
  calendlyRescheduleUrl: REACT_APP_CALENDLY_RESCHEDULE_URL,
  calendlyCancelUrl: REACT_APP_CALENDLY_CANCEL_URL,
  commonBaseUrl: REACT_APP_COMMON_BASE_URL,
  websiteDomain: REACT_APP_WEBSITE_DOMAIN,
  googleTagManagerId: REACT_APP_GTM_ID,
  zendeskKey: REACT_APP_ZENDESK_KEY,
};

export default config;
