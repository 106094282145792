import React, { FC, useEffect, useState } from 'react';
import { Route, RouteProps, RouteComponentProps } from 'react-router-dom';

import { Auth } from 'src/config/amplify';

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps>;
}

const PrivateRouter: FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const user = await Auth.currentAuthenticatedUser();
        setAuthenticated(!!user);
        setLoading(false);
      } catch (e) {
        Auth.signOut();
      }
    })();
  }, []);

  return <Route {...rest} render={props => !loading && authenticated && <Component {...props} />} />;
};

export default PrivateRouter;
