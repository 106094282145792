export const DESIGN = '/design';
export const HOME = '/';
export const UPLOAD_RESUME = '/upload_resume';
export const SIGNUP = '/signup';
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const FORGET_PASSWORD = '/forget_password';
export const EDUCATION_INFO = '/education_info';
export const USER_INFO = '/user-info';
export const ONLINE_EXAM = '/online_test/:id';
export const HOMEPAGE = '/v1';
