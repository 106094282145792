import React, { FC, Suspense, lazy, useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as routes from 'src/constants/routeUrl';
import PrivateRouter from 'src/components/PrivateRouter';
import Loader from 'src/components/Loader';
// import Zendesk from 'react-zendesk';
import { HubListener, RemoveHubListener } from 'src/config/amplify';
// import config from './config';

const Design = lazy(() => import('src/scenes/design'));
const Signup = lazy(() => import('src/scenes/SignUp'));
const Login = lazy(() => import('src/scenes/Login'));
const Logout = lazy(() => import('src/scenes/Logout'));
const ForgetPassword = lazy(() => import('src/scenes/ForgetPassword'));
const PrivateRoute = lazy(() => import('src/PrivateRoute'));

// const setting = {
//   color: {
//     theme: '#abc',
//   },
//   launcher: {
//     chatLabel: {
//       'en-US': 'Need Help',
//     },
//   },
//   contactForm: {
//     fields: [{ id: 'description', prefill: { '*': 'My pre-filled description' } }],
//   },
// };

const MainRoutes: FC = () => {
  const { push } = useHistory();

  useEffect(() => {
    HubListener(push);
    return () => RemoveHubListener();
  }, [push]);

  return (
    <>
      <Suspense fallback={<Loader />}>
        <Switch>
          {process.env.NODE_ENV !== 'production' && <Route path={routes.DESIGN} component={Design} />}
          <Route exact path={routes.SIGNUP} component={Signup} />
          <Route exact path={routes.LOGIN} component={Login} />
          <Route exact path={routes.LOGOUT} component={Logout} />
          <Route exact path={routes.FORGET_PASSWORD} component={ForgetPassword} />
          <PrivateRouter path={routes.HOME} component={PrivateRoute} />
        </Switch>
        <ToastContainer />
        {/* {config.zendeskKey && <Zendesk defer zendeskKey={config.zendeskKey} {...setting} />} */}
      </Suspense>
    </>
  );
};
export default MainRoutes;
